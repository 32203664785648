<template>
  <w-form>
    <w-flex wrap class="text-center">
      <div class="xs6 pa1">
        <w-input type="date" label-position="left" label="Date" outline v-model="date"  :validators="[validators.required]"/>
      </div>
      <div class="xs6 pa1">
        <w-input type="time" label-position="left" label="Time" outline v-model="time"  :validators="[validators.required]"/>
      </div>

      <div class="xs12 pa1 text-left">
        <w-input type="number" label-position="left" label="Food intake (ml)"  v-model="amount"/>
      </div>
      <div class="xs12 pa1 text-left">
        <w-select :items="selectValues.emissions" v-model="emissions" label="Food emissions" label-position="left">
          <template #item="{ item }">
            <w-icon color="primary" class="mr1">mdi mdi-{{ item.icon }}</w-icon>
            <span class="ml1">{{ item.label }}</span>
          </template>
        </w-select>
      </div>
      <div class="xs12 pa1 text-left">
        <w-select :items="selectValues.feedingMethods" v-model="feedingMethod" label="Feeding Method" label-position="left">
          <template #item="{ item }">
            <w-icon color="primary" class="mr1">mdi mdi-{{ item.icon }}</w-icon>
            <span class="ml1">{{ item.label }}</span>
          </template>
        </w-select>
      </div>
      <div class="xs12 pa1 text-left primary">
        <span class="mr2">Willingness to Eat</span>
        <w-rating
            v-model="willingness"
            icon="mdi mdi-heart-outline"

            step-labels
            :step="1"
            :min="1"
            :max="5">
        </w-rating>
      </div>
      <w-divider class="my6 mx-3"></w-divider>

      <div class="xs12 pa0 text-right">
        <w-button class="ma1" bg-color="secondary" icon="wi-cross" @click="onCancel"></w-button>
        <w-button class="ma1" bg-color="primary" icon="wi-check" @click="onSubmit"></w-button>
      </div>

    </w-flex>


  </w-form>

</template>

<script setup>
import {defineEmits, computed, ref} from 'vue';
import {toISOStringWithLocalTimezone} from "@/shared/to-iso-string-with-local-timezone.function";
import {extractHoursAndMinutesFromDate} from "@/shared/extract-hours-and-minutes-from-date.function";
const validators = {
  required: value => !!value || 'This field is required'
}

const amount = ref(0)
const date = ref(new Date().toISOString().split('T')[0])
const time = ref(toISOStringWithLocalTimezone(new Date()).split('T')[1])
const willingness = ref(5)
const emissions = ref('none')
const feedingMethod = ref('bottle')

const $emit = defineEmits(['submit', 'cancel']);
const foodGiven = computed(() => {
      return !!amount.value;
})

function onCancel() {
      $emit('cancel');
      clean();
}
function clean() {
  amount.value = 0;
  date.value = new Date().toISOString().split('T')[0];
  time.value = toISOStringWithLocalTimezone(new Date()).split('T')[1];
  willingness.value = 5;
  emissions.value = 'none';
  feedingMethod.value = 'bottle';
}
function onSubmit() {
  if (foodGiven.value) {
    $emit('submit', {
      date: date.value,
      time: extractHoursAndMinutesFromDate(time.value),
      amount: amount.value,
      willingness: willingness.value,
      emissions: emissions.value,
      feedingMethod: feedingMethod.value
    });
  } else {
    $emit('submit', {
      date: date.value,
      time: extractHoursAndMinutesFromDate(time.value),
      emissions: emissions.value,
    });
  }
  clean();
}
const selectValues = {
      feedingMethods: [{
        label: 'Breast feeding',
        icon: 'mother-nurse',
        value: 'breastfeeding'
      },{
        label: 'Bottle',
        icon: 'baby-bottle-outline',
        value: 'bottle'
      },{
        label: 'Spoon',
        icon: 'silverware-spoon',
        value: 'spoon'
      },{
        label: 'Tube',
        icon: 'pipe',
        value: 'tube'
      }],
      emissions: [{
        label: 'No emissions',
        icon: 'water-off',
        value: 'none'
      },{
        label: 'Slip',
        icon: 'water-outline',
        value: 'slip'
      },
        {
          label: 'Vomit',
          icon: 'water',
          value: 'vomit'
        }]
    }
</script>